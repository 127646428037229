
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as moment from 'moment';
// import AgroTourDeclaration from 'assets/declarationfiles/agrotourdeclaration.pdf';
import * as service from '../../services/service';

function Sponsorship() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', applicantname: '', address: '', institutename: '', instituteaddress: '',
            documentverifiedstatus: '', registrationCertificateFile: '', profileFile: '', footfallReportFile: '', auditedBalanceSheetFile: '',
            ownerDetailsFile: '', participantDetailsFile: '', auditedStatementFile: '', preMediaPlanFile: '', touristDetailsFile: '',
            purposeFile: '', proposalDetailsFile: '', esitmateDetailsFile: '', artistDetailsFile: '',
            permissionFile: '', securityMeasuresFile: '', usefulSociallyFile: '', deliverablesFile: '', receiptsFile: '',
            auditedIncomeFile: '', guaranteeFile: '', amountAvailableFile: '', reconciliationFile: '', blacklistedFile: '',
            developTourismFile: '', tourismKindFile: '', inviteesFile: '', advertisementFile: '', speakersFile: '',
            celebritiesFile: '', famToursFile: '', tangibleFactorsFile: '', benefitsFile: '', impactAssessmentFile: '',
            kraFile: '', sustainableTourismFile: '', plasticFile: '', womenFile: '', videoFile: '', socialMediaFile: '',
            environmentPerformanceFile: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [registrationDate, setRegistrationDate] = useState(new Date());
    const [eventDate, setEventDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const applicantTypeData = [{ label: "Ltd", value: "Ltd" }, { label: "Pvt Ltd", value: "Pvt Ltd" },
    { label: "Partnership", value: "Partnership" }, { label: "NGO", value: "NGO" }, { label: "Proprietor", value: "Proprietor" },
    { label: "Local Government Bodies", value: "Local Government Bodies" }, { label: "Other", value: "Other" }];

    const [registrationCertificateFile, setRegistrationCertificateFile] = useState('');
    const [profileFile, setProfileFile] = useState('');
    const [footfallReportFile, setFootfallReportFile] = useState('');
    const [auditedBalanceSheetFile, setAuditedBalanceSheetFile] = useState('');
    const [ownerDetailsFile, setOwnerDetailsFile] = useState('');
    const [participantDetailsFile, setParticipantDetailsFile] = useState('');
    const [auditedStatementFile, setAuditedStatementFile] = useState('');
    const [preMediaPlanFile, setPreMediaPlanFile] = useState('');
    const [touristDetailsFile, setTouristDetailsFile] = useState('');
    const [purposeFile, setPurposeFile] = useState('');
    const [proposalDetailsFile, setProposalDetailsFile] = useState('');
    const [esitmateDetailsFile, setEsitmateDetailsFile] = useState('');
    const [artistDetailsFile, setArtistDetailsFile] = useState('');
    const [permissionFile, setPermissionFile] = useState('');
    const [securityMeasuresFile, setSecurityMeasuresFile] = useState('');
    const [usefulSociallyFile, setUsefulSociallyFile] = useState('');
    const [deliverablesFile, setDeliverablesFile] = useState('');
    const [receiptsFile, setReceiptsFile] = useState('');
    const [auditedIncomeFile, setAuditedIncomeFile] = useState('');
    const [guaranteeFile, setGuaranteeFile] = useState('');
    const [amountAvailableFile, setAmountAvailableFile] = useState('');
    const [reconciliationFile, setReconciliationFile] = useState('');
    const [blacklistedFile, setBlacklistedFile] = useState('');
    const [developTourismFile, setDevelopTourismFile] = useState('');
    const [tourismKindFile, setTourismKindFile] = useState('');
    const [inviteesFile, setInviteesFile] = useState('');
    const [advertisementFile, setAdvertisementFile] = useState('');
    const [speakersFile, setSpeakersFile] = useState('');
    const [celebritiesFile, setCelebritiesFile] = useState('');
    const [famToursFile, setFAMToursFile] = useState('');
    const [tangibleFactorsFile, setTangibleFactorsFile] = useState('');
    const [benefitsFile, setBenefitsFile] = useState('');
    const [impactAssessmentFile, setImpactAssessmentFile] = useState('');
    const [kraFile, setKRAFile] = useState('');
    const [sustainableTourismFile, setSustainableTourismFile] = useState('');
    const [plasticFile, setPlasticFile] = useState('');
    const [womenFile, setWomenFile] = useState('');
    const [videoFile, setVideoFile] = useState('');
    const [socialMediaFile, setSocialMediaFile] = useState('');
    const [environmentPerformanceFile, setEnvironmentPerformanceFile] = useState('');

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();

    const getRegions = (id) => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        // const maxSize = 150; // in KB
        const maxSize = 5120; // in KB = 5MB
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (!state.applicantname) {
            isValid = false;
            errs[".applicantname"] = "Please enter Name";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select Applicant Type";
        }
        if (!state.address) {
            isValid = false;
            errs[".address"] = "Please enter Address";
        }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }

        if (!eventDate) {
            isValid = false;
            errs[".eventDate"] = "Please enter Event Date";
        }
        // if (!panFile) {
        //     isValid = false;
        //     errs[".panFile"] = "Please upload file";
        // }

        if (registrationCertificateFile && getExtension(registrationCertificateFile.name).toLowerCase() !== 'pdf'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'jpg'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'jpeg'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'png'
            && getExtension(registrationCertificateFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".registrationCertificateFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (profileFile && getExtension(profileFile.name).toLowerCase() !== 'pdf'
            && getExtension(profileFile.name).toLowerCase() !== 'jpg'
            && getExtension(profileFile.name).toLowerCase() !== 'jpeg'
            && getExtension(profileFile.name).toLowerCase() !== 'png'
            && getExtension(profileFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".profileFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (footfallReportFile && getExtension(footfallReportFile.name).toLowerCase() !== 'pdf'
            && getExtension(footfallReportFile.name).toLowerCase() !== 'jpg'
            && getExtension(footfallReportFile.name).toLowerCase() !== 'jpeg'
            && getExtension(footfallReportFile.name).toLowerCase() !== 'png'
            && getExtension(footfallReportFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".footfallReportFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (auditedBalanceSheetFile && getExtension(auditedBalanceSheetFile.name).toLowerCase() !== 'pdf'
            && getExtension(auditedBalanceSheetFile.name).toLowerCase() !== 'jpg'
            && getExtension(auditedBalanceSheetFile.name).toLowerCase() !== 'jpeg'
            && getExtension(auditedBalanceSheetFile.name).toLowerCase() !== 'png'
            && getExtension(auditedBalanceSheetFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".auditedBalanceSheetFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (ownerDetailsFile && getExtension(ownerDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(ownerDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(ownerDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(ownerDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(ownerDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".ownerDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (participantDetailsFile && getExtension(participantDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(participantDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".participantDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (auditedStatementFile && getExtension(auditedStatementFile.name).toLowerCase() !== 'pdf'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'jpg'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'jpeg'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'png'
            && getExtension(auditedStatementFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".auditedStatementFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (preMediaPlanFile && getExtension(preMediaPlanFile.name).toLowerCase() !== 'pdf'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'jpg'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'jpeg'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'png'
            && getExtension(preMediaPlanFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".preMediaPlanFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (touristDetailsFile && getExtension(touristDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(touristDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(touristDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(touristDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(touristDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".touristDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (purposeFile && getExtension(purposeFile.name).toLowerCase() !== 'pdf'
            && getExtension(purposeFile.name).toLowerCase() !== 'jpg'
            && getExtension(purposeFile.name).toLowerCase() !== 'jpeg'
            && getExtension(purposeFile.name).toLowerCase() !== 'png'
            && getExtension(purposeFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".purposeFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (proposalDetailsFile && getExtension(proposalDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(proposalDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".proposalDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (esitmateDetailsFile && getExtension(esitmateDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(esitmateDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(esitmateDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(esitmateDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(esitmateDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".esitmateDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (artistDetailsFile && getExtension(artistDetailsFile.name).toLowerCase() !== 'pdf'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'jpg'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'png'
            && getExtension(artistDetailsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".artistDetailsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (permissionFile && getExtension(permissionFile.name).toLowerCase() !== 'pdf'
            && getExtension(permissionFile.name).toLowerCase() !== 'jpg'
            && getExtension(permissionFile.name).toLowerCase() !== 'jpeg'
            && getExtension(permissionFile.name).toLowerCase() !== 'png'
            && getExtension(permissionFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".permissionFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (securityMeasuresFile && getExtension(securityMeasuresFile.name).toLowerCase() !== 'pdf'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'jpg'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'jpeg'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'png'
            && getExtension(securityMeasuresFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".securityMeasuresFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (usefulSociallyFile && getExtension(usefulSociallyFile.name).toLowerCase() !== 'pdf'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'jpg'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'jpeg'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'png'
            && getExtension(usefulSociallyFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".usefulSociallyFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (deliverablesFile && getExtension(deliverablesFile.name).toLowerCase() !== 'pdf'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'jpg'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'jpeg'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'png'
            && getExtension(deliverablesFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".deliverablesFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (receiptsFile && getExtension(receiptsFile.name).toLowerCase() !== 'pdf'
            && getExtension(receiptsFile.name).toLowerCase() !== 'jpg'
            && getExtension(receiptsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(receiptsFile.name).toLowerCase() !== 'png'
            && getExtension(receiptsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".receiptsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (auditedIncomeFile && getExtension(auditedIncomeFile.name).toLowerCase() !== 'pdf'
            && getExtension(auditedIncomeFile.name).toLowerCase() !== 'jpg'
            && getExtension(auditedIncomeFile.name).toLowerCase() !== 'jpeg'
            && getExtension(auditedIncomeFile.name).toLowerCase() !== 'png'
            && getExtension(auditedIncomeFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".auditedIncomeFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (guaranteeFile && getExtension(guaranteeFile.name).toLowerCase() !== 'pdf'
            && getExtension(guaranteeFile.name).toLowerCase() !== 'jpg'
            && getExtension(guaranteeFile.name).toLowerCase() !== 'jpeg'
            && getExtension(guaranteeFile.name).toLowerCase() !== 'png'
            && getExtension(guaranteeFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".guaranteeFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (amountAvailableFile && getExtension(amountAvailableFile.name).toLowerCase() !== 'pdf'
            && getExtension(amountAvailableFile.name).toLowerCase() !== 'jpg'
            && getExtension(amountAvailableFile.name).toLowerCase() !== 'jpeg'
            && getExtension(amountAvailableFile.name).toLowerCase() !== 'png'
            && getExtension(amountAvailableFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".amountAvailableFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (reconciliationFile && getExtension(reconciliationFile.name).toLowerCase() !== 'pdf'
            && getExtension(reconciliationFile.name).toLowerCase() !== 'jpg'
            && getExtension(reconciliationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(reconciliationFile.name).toLowerCase() !== 'png'
            && getExtension(reconciliationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".reconciliationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (blacklistedFile && getExtension(blacklistedFile.name).toLowerCase() !== 'pdf'
            && getExtension(blacklistedFile.name).toLowerCase() !== 'jpg'
            && getExtension(blacklistedFile.name).toLowerCase() !== 'jpeg'
            && getExtension(blacklistedFile.name).toLowerCase() !== 'png'
            && getExtension(blacklistedFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".blacklistedFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (developTourismFile && getExtension(developTourismFile.name).toLowerCase() !== 'pdf'
            && getExtension(developTourismFile.name).toLowerCase() !== 'jpg'
            && getExtension(developTourismFile.name).toLowerCase() !== 'jpeg'
            && getExtension(developTourismFile.name).toLowerCase() !== 'png'
            && getExtension(developTourismFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".developTourismFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (tourismKindFile && getExtension(tourismKindFile.name).toLowerCase() !== 'pdf'
            && getExtension(tourismKindFile.name).toLowerCase() !== 'jpg'
            && getExtension(tourismKindFile.name).toLowerCase() !== 'jpeg'
            && getExtension(tourismKindFile.name).toLowerCase() !== 'png'
            && getExtension(tourismKindFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".tourismKindFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (inviteesFile && getExtension(inviteesFile.name).toLowerCase() !== 'pdf'
            && getExtension(inviteesFile.name).toLowerCase() !== 'jpg'
            && getExtension(inviteesFile.name).toLowerCase() !== 'jpeg'
            && getExtension(inviteesFile.name).toLowerCase() !== 'png'
            && getExtension(inviteesFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".inviteesFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (advertisementFile && getExtension(advertisementFile.name).toLowerCase() !== 'pdf'
            && getExtension(advertisementFile.name).toLowerCase() !== 'jpg'
            && getExtension(advertisementFile.name).toLowerCase() !== 'jpeg'
            && getExtension(advertisementFile.name).toLowerCase() !== 'png'
            && getExtension(advertisementFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".advertisementFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (speakersFile && getExtension(speakersFile.name).toLowerCase() !== 'pdf'
            && getExtension(speakersFile.name).toLowerCase() !== 'jpg'
            && getExtension(speakersFile.name).toLowerCase() !== 'jpeg'
            && getExtension(speakersFile.name).toLowerCase() !== 'png'
            && getExtension(speakersFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".speakersFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (celebritiesFile && getExtension(celebritiesFile.name).toLowerCase() !== 'pdf'
            && getExtension(celebritiesFile.name).toLowerCase() !== 'jpg'
            && getExtension(celebritiesFile.name).toLowerCase() !== 'jpeg'
            && getExtension(celebritiesFile.name).toLowerCase() !== 'png'
            && getExtension(celebritiesFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".celebritiesFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (famToursFile && getExtension(famToursFile.name).toLowerCase() !== 'pdf'
            && getExtension(famToursFile.name).toLowerCase() !== 'jpg'
            && getExtension(famToursFile.name).toLowerCase() !== 'jpeg'
            && getExtension(famToursFile.name).toLowerCase() !== 'png'
            && getExtension(famToursFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".famToursFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (tangibleFactorsFile && getExtension(tangibleFactorsFile.name).toLowerCase() !== 'pdf'
            && getExtension(tangibleFactorsFile.name).toLowerCase() !== 'jpg'
            && getExtension(tangibleFactorsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(tangibleFactorsFile.name).toLowerCase() !== 'png'
            && getExtension(tangibleFactorsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".tangibleFactorsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (benefitsFile && getExtension(benefitsFile.name).toLowerCase() !== 'pdf'
            && getExtension(benefitsFile.name).toLowerCase() !== 'jpg'
            && getExtension(benefitsFile.name).toLowerCase() !== 'jpeg'
            && getExtension(benefitsFile.name).toLowerCase() !== 'png'
            && getExtension(benefitsFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".benefitsFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (impactAssessmentFile && getExtension(impactAssessmentFile.name).toLowerCase() !== 'pdf'
            && getExtension(impactAssessmentFile.name).toLowerCase() !== 'jpg'
            && getExtension(impactAssessmentFile.name).toLowerCase() !== 'jpeg'
            && getExtension(impactAssessmentFile.name).toLowerCase() !== 'png'
            && getExtension(impactAssessmentFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".impactAssessmentFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (kraFile && getExtension(kraFile.name).toLowerCase() !== 'pdf'
            && getExtension(kraFile.name).toLowerCase() !== 'jpg'
            && getExtension(kraFile.name).toLowerCase() !== 'jpeg'
            && getExtension(kraFile.name).toLowerCase() !== 'png'
            && getExtension(kraFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".kraFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (sustainableTourismFile && getExtension(sustainableTourismFile.name).toLowerCase() !== 'pdf'
            && getExtension(sustainableTourismFile.name).toLowerCase() !== 'jpg'
            && getExtension(sustainableTourismFile.name).toLowerCase() !== 'jpeg'
            && getExtension(sustainableTourismFile.name).toLowerCase() !== 'png'
            && getExtension(sustainableTourismFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".sustainableTourismFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (plasticFile && getExtension(plasticFile.name).toLowerCase() !== 'pdf'
            && getExtension(plasticFile.name).toLowerCase() !== 'jpg'
            && getExtension(plasticFile.name).toLowerCase() !== 'jpeg'
            && getExtension(plasticFile.name).toLowerCase() !== 'png'
            && getExtension(plasticFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".plasticFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (womenFile && getExtension(womenFile.name).toLowerCase() !== 'pdf'
            && getExtension(womenFile.name).toLowerCase() !== 'jpg'
            && getExtension(womenFile.name).toLowerCase() !== 'jpeg'
            && getExtension(womenFile.name).toLowerCase() !== 'png'
            && getExtension(womenFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".womenFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (videoFile && getExtension(videoFile.name).toLowerCase() !== 'pdf'
            && getExtension(videoFile.name).toLowerCase() !== 'jpg'
            && getExtension(videoFile.name).toLowerCase() !== 'jpeg'
            && getExtension(videoFile.name).toLowerCase() !== 'png'
            && getExtension(videoFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".videoFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (socialMediaFile && getExtension(socialMediaFile.name).toLowerCase() !== 'pdf'
            && getExtension(socialMediaFile.name).toLowerCase() !== 'jpg'
            && getExtension(socialMediaFile.name).toLowerCase() !== 'jpeg'
            && getExtension(socialMediaFile.name).toLowerCase() !== 'png'
            && getExtension(socialMediaFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".socialMediaFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (environmentPerformanceFile && getExtension(environmentPerformanceFile.name).toLowerCase() !== 'pdf'
            && getExtension(environmentPerformanceFile.name).toLowerCase() !== 'jpg'
            && getExtension(environmentPerformanceFile.name).toLowerCase() !== 'jpeg'
            && getExtension(environmentPerformanceFile.name).toLowerCase() !== 'png'
            && getExtension(environmentPerformanceFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".environmentPerformanceFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (registrationCertificateFile && registrationCertificateFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".registrationCertificateFile"] = "File size limit 5 MB";
        }
        if (profileFile && profileFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".profileFile"] = "File size limit 5 MB";
        }
        if (footfallReportFile && footfallReportFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".footfallReportFile"] = "File size limit 5 MB";
        }
        if (auditedBalanceSheetFile && auditedBalanceSheetFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".auditedBalanceSheetFile"] = "File size limit 5 MB";
        }
        if (ownerDetailsFile && ownerDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".ownerDetailsFile"] = "File size limit 5 MB";
        }
        if (participantDetailsFile && participantDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".participantDetailsFile"] = "File size limit 5 MB";
        }
        if (auditedStatementFile && auditedStatementFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".auditedStatementFile"] = "File size limit 5 MB";
        }
        if (preMediaPlanFile && preMediaPlanFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".preMediaPlanFile"] = "File size limit 5 MB";
        }
        if (touristDetailsFile && touristDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".touristDetailsFile"] = "File size limit 5 MB";
        }
        if (purposeFile && purposeFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".purposeFile"] = "File size limit 5 MB";
        }
        if (proposalDetailsFile && proposalDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".proposalDetailsFile"] = "File size limit 5 MB";
        }
        if (esitmateDetailsFile && esitmateDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".esitmateDetailsFile"] = "File size limit 5 MB";
        }
        if (artistDetailsFile && artistDetailsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".artistDetailsFile"] = "File size limit 5 MB";
        }
        if (permissionFile && permissionFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".permissionFile"] = "File size limit 5 MB";
        }
        if (securityMeasuresFile && securityMeasuresFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".securityMeasuresFile"] = "File size limit 5 MB";
        }
        if (usefulSociallyFile && usefulSociallyFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".usefulSociallyFile"] = "File size limit 5 MB";
        }
        if (deliverablesFile && deliverablesFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".deliverablesFile"] = "File size limit 5 MB";
        }
        if (receiptsFile && receiptsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".receiptsFile"] = "File size limit 5 MB";
        }
        if (auditedIncomeFile && auditedIncomeFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".auditedIncomeFile"] = "File size limit 5 MB";
        }
        if (guaranteeFile && guaranteeFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".guaranteeFile"] = "File size limit 5 MB";
        }
        if (amountAvailableFile && amountAvailableFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".amountAvailableFile"] = "File size limit 5 MB";
        }
        if (reconciliationFile && reconciliationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".reconciliationFile"] = "File size limit 5 MB";
        }
        if (blacklistedFile && blacklistedFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".blacklistedFile"] = "File size limit 5 MB";
        }
        if (developTourismFile && developTourismFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".developTourismFile"] = "File size limit 5 MB";
        }
        if (tourismKindFile && tourismKindFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".tourismKindFile"] = "File size limit 5 MB";
        }
        if (inviteesFile && inviteesFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".inviteesFile"] = "File size limit 5 MB";
        }
        if (advertisementFile && advertisementFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".advertisementFile"] = "File size limit 5 MB";
        }
        if (speakersFile && speakersFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".speakersFile"] = "File size limit 5 MB";
        }
        if (celebritiesFile && celebritiesFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".celebritiesFile"] = "File size limit 5 MB";
        }
        if (famToursFile && famToursFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".famToursFile"] = "File size limit 5 MB";
        }
        if (tangibleFactorsFile && tangibleFactorsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".tangibleFactorsFile"] = "File size limit 5 MB";
        }
        if (benefitsFile && benefitsFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".benefitsFile"] = "File size limit 5 MB";
        }
        if (impactAssessmentFile && impactAssessmentFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".impactAssessmentFile"] = "File size limit 5 MB";
        }
        if (kraFile && kraFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".kraFile"] = "File size limit 5 MB";
        }
        if (sustainableTourismFile && sustainableTourismFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".sustainableTourismFile"] = "File size limit 5 MB";
        }
        if (plasticFile && plasticFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".plasticFile"] = "File size limit 5 MB";
        }
        if (womenFile && womenFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".womenFile"] = "File size limit 5 MB";
        }
        if (videoFile && videoFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".videoFile"] = "File size limit 5 MB";
        }
        if (socialMediaFile && socialMediaFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".socialMediaFile"] = "File size limit 5 MB";
        }
        if (environmentPerformanceFile && environmentPerformanceFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".environmentPerformanceFile"] = "File size limit 5 MB";
        }

        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("SponsorId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("ApplicantName", state.applicantname);
            formData.append("ApplicantType", applicantType.value);
            formData.append("Address", state.address);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("InstituteName", state.institutename);
            formData.append("InstituteAddress", state.instituteaddress);
            formData.append("EventDate", (new Date(eventDate)).toUTCString());
            formData.append("RegistrationDate", (new Date(registrationDate)).toUTCString());

            formData.append("registrationCertificateFileRemarks", state.registrationCertificateFile);
            formData.append("profileFileRemarks", state.profileFile);
            formData.append("footfallReportFileRemarks", state.footfallReportFile);
            formData.append("auditedBalanceSheetFileRemarks", state.auditedBalanceSheetFile);
            formData.append("ownerDetailsFileRemarks", state.ownerDetailsFile);
            formData.append("participantDetailsFileRemarks", state.participantDetailsFile);
            formData.append("auditedStatementFileRemarks", state.auditedStatementFile);
            formData.append("preMediaPlanFileRemarks", state.preMediaPlanFile);
            formData.append("touristDetailsFileRemarks", state.touristDetailsFile);
            formData.append("purposeFileRemarks", state.purposeFile);
            formData.append("proposalDetailsFileRemarks", state.proposalDetailsFile);
            formData.append("esitmateDetailsFileRemarks", state.esitmateDetailsFile);
            formData.append("artistDetailsFileRemarks", state.artistDetailsFile);
            formData.append("permissionFileRemarks", state.permissionFile);
            formData.append("securityMeasuresFileRemarks", state.securityMeasuresFile);
            formData.append("usefulSociallyFileRemarks", state.usefulSociallyFile);
            formData.append("deliverablesFileRemarks", state.deliverablesFile);
            formData.append("receiptsFileRemarks", state.receiptsFile);
            formData.append("auditedIncomeFileRemarks", state.auditedIncomeFile);
            formData.append("guaranteeFileRemarks", state.guaranteeFile);
            formData.append("amountAvailableFileRemarks", state.amountAvailableFile);
            formData.append("reconciliationFileRemarks", state.reconciliationFile);
            formData.append("blacklistedFileRemarks", state.blacklistedFile);
            formData.append("developTourismFileRemarks", state.developTourismFile);
            formData.append("tourismKindFileRemarks", state.tourismKindFile);
            formData.append("inviteesFileRemarks", state.inviteesFile);
            formData.append("advertisementFileRemarks", state.advertisementFile);
            formData.append("speakersFileRemarks", state.speakersFile);
            formData.append("celebritiesFileRemarks", state.celebritiesFile);
            formData.append("famToursFileRemarks", state.famToursFile);
            formData.append("tangibleFactorsFileRemarks", state.tangibleFactorsFile);
            formData.append("benefitsFileRemarks", state.benefitsFile);
            formData.append("impactAssessmentFileRemarks", state.impactAssessmentFile);
            formData.append("kraFileRemarks", state.kraFile);
            formData.append("sustainableTourismFileRemarks", state.sustainableTourismFile);
            formData.append("plasticFileRemarks", state.plasticFile);
            formData.append("womenFileRemarks", state.womenFile);
            formData.append("videoFileRemarks", state.videoFile);
            formData.append("socialMediaFileRemarks", state.socialMediaFile);
            formData.append("environmentPerformanceFileRemarks", state.environmentPerformanceFile);

            formData.append("RegistrationCertificateFile", registrationCertificateFile);
            formData.append("ProfileFile", profileFile);
            formData.append("FootfallReportFile", footfallReportFile);
            formData.append("AuditedBalanceSheetFile", auditedBalanceSheetFile);
            formData.append("OwnerDetailsFile", ownerDetailsFile);
            formData.append("ParticipantDetailsFile", participantDetailsFile);
            formData.append("AuditedStatementFile", auditedStatementFile);
            formData.append("PreMediaPlanFile", preMediaPlanFile);
            formData.append("TouristDetailsFile", touristDetailsFile);
            formData.append("PurposeFile", purposeFile);
            formData.append("ProposalDetailsFile", proposalDetailsFile);
            formData.append("EsitmateDetailsFile", esitmateDetailsFile);
            formData.append("ArtistDetailsFile", artistDetailsFile);
            formData.append("PermissionFile", permissionFile);
            formData.append("SecurityMeasuresFile", securityMeasuresFile);
            formData.append("UsefulSociallyFile", usefulSociallyFile);
            formData.append("DeliverablesFile", deliverablesFile);
            formData.append("ReceiptsFile", receiptsFile);
            formData.append("AuditedIncomeFile", auditedIncomeFile);
            formData.append("GuaranteeFile", guaranteeFile);
            formData.append("AmountAvailableFile", amountAvailableFile);
            formData.append("ReconciliationFile", reconciliationFile);
            formData.append("BlacklistedFile", blacklistedFile);
            formData.append("DevelopTourismFile", developTourismFile);
            formData.append("TourismKindFile", tourismKindFile);
            formData.append("InviteesFile", inviteesFile);
            formData.append("AdvertisementFile", advertisementFile);
            formData.append("SpeakersFile", speakersFile);
            formData.append("CelebritiesFile", celebritiesFile);
            formData.append("FAMToursFile", famToursFile);
            formData.append("TangibleFactorsFile", tangibleFactorsFile);
            formData.append("BenefitsFile", benefitsFile);
            formData.append("ImpactAssessmentFile", impactAssessmentFile);
            formData.append("KRAFile", kraFile);
            formData.append("SustainableTourismFile", sustainableTourismFile);
            formData.append("PlasticFile", plasticFile);
            formData.append("WomenFile", womenFile);
            formData.append("VideoFile", videoFile);
            formData.append("SocialMediaFile", socialMediaFile);
            formData.append("EnvironmentPerformanceFile", environmentPerformanceFile);

            fetch(
                service.ADD_SPONSORSHIP_REGISTRATION,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Congratulations! Your application has been submitted successfully.");
                        navigate('/sponsorshipsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Sponsorship Services Registration / आर्थिक सहाय्य प्रस्ताव नोंदणी
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required
                                                        disabled={window.sessionStorage.getItem('userData')}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Full Name of Applicant"
                                                        fullWidth value={state.applicantname || ''}
                                                        name="applicantname"
                                                        onChange={changeHandler}
                                                        id="applicantname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantname"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    Registration Date
                                                    <DatePicker
                                                        id="registrationDate"
                                                        selected={registrationDate}
                                                        onChange={date => setRegistrationDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".registrationDate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Full Address of Applicant" fullWidth value={state.address || ''}
                                                        name="address"
                                                        onChange={changeHandler}
                                                        id="address"
                                                        required
                                                        multiline />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Institute Name"
                                                        fullWidth value={state.institutename || ''}
                                                        name="institutename"
                                                        onChange={changeHandler}
                                                        id="institutename"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".institutename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Institute Address"
                                                        fullWidth value={state.instituteaddress || ''}
                                                        name="instituteaddress"
                                                        onChange={changeHandler}
                                                        id="instituteaddress"
                                                        multiline />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".instituteaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    Event Date
                                                    <DatePicker
                                                        id="eventDate"
                                                        selected={eventDate}
                                                        onChange={date => setEventDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".eventDate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell width="10%" style={{ fontWeight: 'bold' }}>1</TableCell>
                                                            <TableCell width="70%" style={{ fontWeight: 'bold' }}>
                                                                संस्थेचे नोंदणी प्रमाणपत्र व तारीख/Organisation  Registration Certificate and Date (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="registrationCertificateFile"
                                                                    type="file"
                                                                    onChange={ev => setRegistrationCertificateFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".registrationCertificateFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.registrationCertificateFile || ''}
                                                                    name="registrationCertificateFile"
                                                                    onChange={changeHandler}
                                                                    id="registrationCertificateFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".registrationCertificateFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>2</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                संस्थेचा कार्यानुभव/Experience of Organisation (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="profileFile"
                                                                    type="file"
                                                                    onChange={ev => setProfileFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".profileFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.profileFile || ''}
                                                                    name="profileFile"
                                                                    onChange={changeHandler}
                                                                    id="profileFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".profileFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>3</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या पूर्वी महोत्सव/कार्यक्रम केला असल्यास त्याचा तीन वर्षाचा तपशील - फल निष्पती अहवाल/If the festival / event has been done earlier, its three year details with footfall report of Events (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="footfallReportFile"
                                                                    type="file"
                                                                    onChange={ev => setFootfallReportFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".footfallReportFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.footfallReportFile || ''}
                                                                    name="footfallReportFile"
                                                                    onChange={changeHandler}
                                                                    id="footfallReportFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".footfallReportFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>4</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                संस्थेची ३ वर्षीचा  लेखापरीक्षत ताळेबंद /Origination Audited Balance Sheet for 3 Years (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="auditedBalanceSheetFile"
                                                                    type="file"
                                                                    onChange={ev => setAuditedBalanceSheetFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedBalanceSheetFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.auditedBalanceSheetFile || ''}
                                                                    name="auditedBalanceSheetFile"
                                                                    onChange={changeHandler}
                                                                    id="auditedBalanceSheetFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedBalanceSheetFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>5</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                संस्थेच्या मालकाची माहिती/Details of Organisation owner  (Profile) (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="ownerDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setOwnerDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ownerDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.ownerDetailsFile || ''}
                                                                    name="ownerDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="ownerDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ownerDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>6</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                सदर महोत्सवात/कार्यक्रमात सहभागी होणाऱ्या सह-प्रायोजक, सहभागी संस्था यांचा तपशील  तसेच कार्यादेशाची प्रत/Details of co-sponsors/organization participating in this festival/event and copies of work order (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="participantDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setParticipantDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".participantDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.participantDetailsFile || ''}
                                                                    name="participantDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="participantDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".participantDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>7</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                अन्य माध्यमातून प्रायोजकत्व उभारण्यात येणार आहे त्याचा लेखापरिक्षत तपशील/Audited statement of Sponsorship generated from other sources (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="auditedStatementFile"
                                                                    type="file"
                                                                    onChange={ev => setAuditedStatementFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedStatementFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.auditedStatementFile || ''}
                                                                    name="auditedStatementFile"
                                                                    onChange={changeHandler}
                                                                    id="auditedStatementFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedStatementFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>8</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                संस्थेच्या महोत्सवाचा/कार्यक्रमाचा (पूर्व –प्रसिद्धी )मिडिया प्लान आणि संबधित कागदपत्रे/Festival/Event Pre Media Plan with Supporting document (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="preMediaPlanFile"
                                                                    type="file"
                                                                    onChange={ev => setPreMediaPlanFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".preMediaPlanFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.preMediaPlanFile || ''}
                                                                    name="preMediaPlanFile"
                                                                    onChange={changeHandler}
                                                                    id="preMediaPlanFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".artistDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>9</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                गत महोत्सवामध्ये /कार्यक्रमामध्ये उपस्थित असणाऱ्या देशी-विदेशी पर्यटकांचा वर्षनिहाय तपशील  / Year Wise details of domestic and foreign Tourist present in the festival / event   (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="touristDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setTouristDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".touristDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.touristDetailsFile || ''}
                                                                    name="touristDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="touristDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".touristDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>10</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                सदर महोत्सव/ कार्यक्रम पर्यटन संवर्धन / वृद्धी साठी आहे का? असल्यास कार्यक्रमाचे ध्येय, उदिष्ट आणि काय साध्य/निष्पन्न  करावयाचे आहे त्याची माहिती / Is this festival /Event is related for tourism promotion/ growth if yes give the  details of objective, aims, what to achieve (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="purposeFile"
                                                                    type="file"
                                                                    onChange={ev => setPurposeFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purposeFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.purposeFile || ''}
                                                                    name="purposeFile"
                                                                    onChange={changeHandler}
                                                                    id="purposeFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purposeFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>11</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                सदर प्रस्तावासोबत महोत्सवाचा सविस्तर तपशिल उदा.महोत्सवाचे नाव, स्थळ, कालावधी, कार्यक्रमाची रुपरेषा, स्वरूप, तसेच महामंडळाकडून अपेक्षा (आर्थिक )/Detailed description of the festival along with this proposal: eg name, place, period of the festival/ Event  outline of the program, format, as well as financial  expectations from MTDC (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="proposalDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setProposalDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                    required
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".proposalDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.proposalDetailsFile || ''}
                                                                    name="proposalDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="proposalDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".proposalDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>12</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                महोत्सव/कार्यक्रमाचा अंदाजित खर्च (लेखा परीक्षित ), प्राप्त होणारे प्रयोजकत्व निधी उपलब्धतेबाबतची  माहिती (लेखापरीक्षित )  तसेच त्यामधून होणारा नफा /तोटा तपशील / Estimated Income & expenditure on the festival/event (audited) &  information regarding the availability of funding (audited) and profit/loss details (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="esitmateDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setEsitmateDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".esitmateDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.esitmateDetailsFile || ''}
                                                                    name="esitmateDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="esitmateDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".esitmateDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>13</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                महोत्सवात जर सांस्कृतिक कार्यक्रमाचा समावेश असल्यास कलाकाराचा तपशील (नाव, कला, नृत्य प्रकार, संपर्क इ.) / Artist details (name, art, dance type, contact, etc.), if included in a cultural program (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="artistDetailsFile"
                                                                    type="file"
                                                                    onChange={ev => setArtistDetailsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".artistDetailsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.artistDetailsFile || ''}
                                                                    name="artistDetailsFile"
                                                                    onChange={changeHandler}
                                                                    id="artistDetailsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".artistDetailsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>14</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                आयोजकांनी कार्यक्रमासाठी लागणाऱ्या विविध परवानग्या  (शासकीय) घेतल्या आहेत  कि नाही (असल्यास संबधित कागदपत्रे) / Whether or not the organizers have taken various permission (government) for the program (if relevant documents) (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="permissionFile"
                                                                    type="file"
                                                                    onChange={ev => setPermissionFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".permissionFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.permissionFile || ''}
                                                                    name="permissionFile"
                                                                    onChange={changeHandler}
                                                                    id="permissionFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".permissionFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>15</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                कार्यक्रमादरम्यान  सुरक्षा विषयक घेण्यात येणारी काळजी  (माहिती)/Details for secuirty measures taken by organizer during festival /Event (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="securityMeasuresFile"
                                                                    type="file"
                                                                    onChange={ev => setSecurityMeasuresFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".securityMeasuresFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.securityMeasuresFile || ''}
                                                                    name="securityMeasuresFile"
                                                                    onChange={changeHandler}
                                                                    id="securityMeasuresFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".securityMeasuresFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>16</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                सदर महोत्सव/ कार्यक्रम पर्यावरण/ सामाजिकदृष्ट्या कश्याप्रकारे उपयोगी ठरणार आहे. / How this festival / event will be useful for environmentally / socially? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="usefulSociallyFile"
                                                                    type="file"
                                                                    onChange={ev => setUsefulSociallyFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".usefulSociallyFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.usefulSociallyFile || ''}
                                                                    name="usefulSociallyFile"
                                                                    onChange={changeHandler}
                                                                    id="usefulSociallyFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".usefulSociallyFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>17</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                सदर महोत्सव/ कार्यक्रमात सहभागी होण्याकरिता आयोजकानी पर्यटन संचालनालया देऊ केलेले Deliverables / Deliverables offered by  organizer to Maharashtra Tourism for participating in festival / Event (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="deliverablesFile"
                                                                    type="file"
                                                                    onChange={ev => setDeliverablesFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".deliverablesFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.deliverablesFile || ''}
                                                                    name="deliverablesFile"
                                                                    onChange={changeHandler}
                                                                    id="deliverablesFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".deliverablesFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>18</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                संस्थेने या पूर्वी किती कार्यक्रम केले व त्यापोटी शासनाला करमणूक कर भणा केलेल्या पावत्या उपलब्ध करुन देणे./To make available the receipt of entertainment tax paid to the Government on the basis of how many Exhibition/ Event  the organization has done earlier (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="receiptsFile"
                                                                    type="file"
                                                                    onChange={ev => setReceiptsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".receiptsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.receiptsFile || ''}
                                                                    name="receiptsFile"
                                                                    onChange={changeHandler}
                                                                    id="receiptsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".receiptsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>19</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                कार्यक्रमाचा जमा-खर्च यांची लेखापरिक्षक प्रत./Copy of Audited income- Expenditure of Event (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="auditedIncomeFile"
                                                                    type="file"
                                                                    onChange={ev => setAuditedIncomeFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedIncomeFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.auditedIncomeFile || ''}
                                                                    name="auditedIncomeFile"
                                                                    onChange={changeHandler}
                                                                    id="auditedIncomeFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".auditedIncomeFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>20</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                महाराष्ट्र पर्यटनाची प्रसिध्दी 3 वर्षाकरीता विना मोबदला करण्याबाबतचे हमीपत्र पर्यटन संचालनालयास, महाराष्ट्र शासन उपलब्ध करुन देण्यात यावे./Letter of Guarantee to be given to be made available to the Directorate of Tourism for undertaking free publicity of Maharashtra Tourism for the period three years (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="guaranteeFile"
                                                                    type="file"
                                                                    onChange={ev => setGuaranteeFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".guaranteeFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.guaranteeFile || ''}
                                                                    name="guaranteeFile"
                                                                    onChange={changeHandler}
                                                                    id="guaranteeFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".guaranteeFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>21</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमातून विविध टी.व्ही. चॅनेल्स, यु टूब्स तसेच फेसबुक इत्यादीच्या माध्यमांतून प्रसारण हक्क विकून काही उपन्न आपल्या संस्थेस मिळणार असल्यास तर त्यामधील रक्कम शासनास उपलब्ध करुन देणार आहात का?/Whether the organizer will make the amount available to the government from earning broadcast rights through Channels, YouTube and Facebook etc of the Event (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="amountAvailableFile"
                                                                    type="file"
                                                                    onChange={ev => setAmountAvailableFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amountAvailableFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.amountAvailableFile || ''}
                                                                    name="amountAvailableFile"
                                                                    onChange={changeHandler}
                                                                    id="amountAvailableFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amountAvailableFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>22</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या वर्षीच्या कार्यक्रमाचा एकूण जमा-खर्च किती आहे व एकूण खर्च कसा करणार त्याचा वित्तीय ताळमेळ/Reconciliation of how much total expenditure this year`s program is and how much the total expenditure will be spent (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="reconciliationFile"
                                                                    type="file"
                                                                    onChange={ev => setReconciliationFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".reconciliationFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.reconciliationFile || ''}
                                                                    name="reconciliationFile"
                                                                    onChange={changeHandler}
                                                                    id="reconciliationFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".reconciliationFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>23</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                अर्ज करणारी संस्था / कंपनी शासनाच्या काळ्या यादीत समावेश आहे किवां कसे ? तसेच आयोजकांद्वारे महाराष्ट्र शासन विरोधात कोणतेही न्यायालयीन प्रकरण दाखल आहे किवां कसे?/How or why is the applicant organization / company blacklisted by the government? Also, is there any court case filed against the Government of Maharashtra by the organizers or how? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="blacklistedFile"
                                                                    type="file"
                                                                    onChange={ev => setBlacklistedFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".blacklistedFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.blacklistedFile || ''}
                                                                    name="blacklistedFile"
                                                                    onChange={changeHandler}
                                                                    id="blacklistedFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".blacklistedFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>24</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या महोत्सवात महाराष्ट्राच्या पर्यटनात वाढ होईल, प्रसिध्दी होईल असे कोणते कार्यक्रम हाती घेतले आहेत?/In this environment, what programs have been taken that will develop the tourism of Maharashtra? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="developTourismFile"
                                                                    type="file"
                                                                    onChange={ev => setDevelopTourismFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".developTourismFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.developTourismFile || ''}
                                                                    name="developTourismFile"
                                                                    onChange={changeHandler}
                                                                    id="developTourismFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".developTourismFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>25</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमात कोणत्या प्रकारच्या पर्यटनास वाव वा उपयोग होणार आहे ? असल्यास त्याचा सविस्तर तपशिल सादर करावा./What kind of tourism is going to be used in this program? If so, please submit the detailed details. (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="tourismKindFile"
                                                                    type="file"
                                                                    onChange={ev => setTourismKindFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tourismKindFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.tourismKindFile || ''}
                                                                    name="tourismKindFile"
                                                                    onChange={changeHandler}
                                                                    id="tourismKindFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tourismKindFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>26</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाव्दारे किती टुर ऑपरेटर्स, इन्फलुएन्सर्स, ब्लॉगर यांना आमंत्रित केले आहे ? त्यांचे निकष काय ठेवण्यात आले आहेत ?/How many tour operators, influencers, bloggers have been invited by this program? What are their criteria? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="inviteesFile"
                                                                    type="file"
                                                                    onChange={ev => setInviteesFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".inviteesFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.inviteesFile || ''}
                                                                    name="inviteesFile"
                                                                    onChange={changeHandler}
                                                                    id="inviteesFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".inviteesFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>27</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाव्दारे/महोत्सवाव्दारे महाराष्ट्र पर्यटनाबाबत लेख, जाहिरात, मुलाखत इ. प्रसिध्द करण्यात येईत त्याची संख्या, आकार इ. तपशील देण्यात यावा./Articles, advertisement, interview etc. about Maharashtra Tourism through this program/festival. will be announced. Give details (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="advertisementFile"
                                                                    type="file"
                                                                    onChange={ev => setAdvertisementFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".advertisementFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.advertisementFile || ''}
                                                                    name="advertisementFile"
                                                                    onChange={changeHandler}
                                                                    id="advertisementFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".advertisementFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>28</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाव्दारे पर्यटन क्षेत्रातील मान्यवरांचे भाषण वा वक्ते म्हणुन आमंत्रित केले आहे का ? त्याचा तपशील सादर करावा./Are dignitaries in the field of tourism invited to speak or as speakers for this program? Its details should be submitted (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="speakersFile"
                                                                    type="file"
                                                                    onChange={ev => setSpeakersFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".speakersFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.speakersFile || ''}
                                                                    name="speakersFile"
                                                                    onChange={changeHandler}
                                                                    id="speakersFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".speakersFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>29</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमास ख्यातनाम व्यक्ती ( Celebrities) आमंत्रित असल्यास त्यांच्याव्दारे महाराष्ट्र टुरिझम/पर्यटन स्थळांबाबत काही  Reels, tweet, instagram, इ. चा तपशील पर्यटन संचालनालयास सादर करावा./If celebrities are invited to this event, they can post some Reels, tweets, Instagram, etc. about Maharashtra tourism/tourist places. The details should be submitted to the Directorate of Tourism (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="celebritiesFile"
                                                                    type="file"
                                                                    onChange={ev => setCelebritiesFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".celebritiesFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.celebritiesFile || ''}
                                                                    name="celebritiesFile"
                                                                    onChange={changeHandler}
                                                                    id="celebritiesFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".celebritiesFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>30</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या महोत्सवाव्दारे/दरम्यान किती फॅम टूर्स/परिचय सहलीचे आयोजन केले आहे त्याचा तपशील./Details of FAM Tours/Introduction Tours are organized during/during the festival (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="famToursFile"
                                                                    type="file"
                                                                    onChange={ev => setFAMToursFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".famToursFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.famToursFile || ''}
                                                                    name="famToursFile"
                                                                    onChange={changeHandler}
                                                                    id="famToursFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".famToursFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>31</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाव्दारे कोणत्या Tangible and Intangible  पर्यटन घटकांची  प्रसिध्दी होणार आहे त्याचा तपशील द्यावा./Give details of which Tangible and Intangible tourism factors will be promoted through this program (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="tangibleFactorsFile"
                                                                    type="file"
                                                                    onChange={ev => setTangibleFactorsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tangibleFactorsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.tangibleFactorsFile || ''}
                                                                    name="tangibleFactorsFile"
                                                                    onChange={changeHandler}
                                                                    id="tangibleFactorsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tangibleFactorsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>32</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाव्दारे स्थानिक लोकांना काय फायदा होणार आहे त्याचा सविस्तर तपशील/Detailed details of how the program will benefit local people (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="benefitsFile"
                                                                    type="file"
                                                                    onChange={ev => setBenefitsFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".benefitsFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.benefitsFile || ''}
                                                                    name="benefitsFile"
                                                                    onChange={changeHandler}
                                                                    id="benefitsFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".benefitsFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>33</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाचा पर्यटनाच्या दृष्टीकोनातुन Impact Assessment अहवाल सोबत जोडावा./An Impact Assessment report of this program from tourism perspective should be attached (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="impactAssessmentFile"
                                                                    type="file"
                                                                    onChange={ev => setImpactAssessmentFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".impactAssessmentFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.impactAssessmentFile || ''}
                                                                    name="impactAssessmentFile"
                                                                    onChange={changeHandler}
                                                                    id="impactAssessmentFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".impactAssessmentFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>34</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमात प्रस्तावित बाबींची सफलता नोंदणी साठी KRA (Key Result Areas) सोबत सादर करावेत./Impact assessment report of this program should be attached from tourism perspective (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="kraFile"
                                                                    type="file"
                                                                    onChange={ev => setKRAFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".kraFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.kraFile || ''}
                                                                    name="kraFile"
                                                                    onChange={changeHandler}
                                                                    id="kraFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".kraFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>35</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमाव्दारे/महोत्सवाव्दारे शाश्वत पर्यटन आणि जबाबदार पर्यटनास कसा वाव मिळणार आहे ?/How do   these events/festivals promote sustainable tourism and cultural tourism? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="sustainableTourismFile"
                                                                    type="file"
                                                                    onChange={ev => setSustainableTourismFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sustainableTourismFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.sustainableTourismFile || ''}
                                                                    name="sustainableTourismFile"
                                                                    onChange={changeHandler}
                                                                    id="sustainableTourismFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sustainableTourismFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>36</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमात प्रतिबंधीत प्लॅस्टीक व थर्माकोलच्या कटलेरीचा, पाण्याच्या प्लॅस्टिक बाटल्यांचा वापर होणार नाही याचे हमिपत्र सादर करावे./A guarantee should be submitted that plastic and thermocol cutlery, plastic water bottles will not be used in this program (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="plasticFile"
                                                                    type="file"
                                                                    onChange={ev => setPlasticFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".plasticFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.plasticFile || ''}
                                                                    name="plasticFile"
                                                                    onChange={changeHandler}
                                                                    id="plasticFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".plasticFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>37</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                या कार्यक्रमात पर्यटन क्षेत्रात उत्कृष्ठ काम करणाऱ्या महिला, स्टार्ट अप, होमस्टे, कृषी पर्यटन केंद्र, पर्यटन गाव यांचा सत्कार आहे का ? वा त्यांना प्रसिध्दी देण्यात येणार आहे का ?/Are women, startups, homestays, agricultural tourism centers, tourism villages felicitated in this program? Or will they be given publicity? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="womenFile"
                                                                    type="file"
                                                                    onChange={ev => setWomenFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".womenFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.womenFile || ''}
                                                                    name="womenFile"
                                                                    onChange={changeHandler}
                                                                    id="womenFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".womenFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>38</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                कार्यक्रमाची Video, Reels, Photos इ. पर्यटन संचालनालयाला उपलब्ध करुन देण्यात येणार आहेत का ?/Video, Reels, Photos etc. of the program. Will it be made available to the Directorate of Tourism? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="videoFile"
                                                                    type="file"
                                                                    onChange={ev => setVideoFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".videoFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.videoFile || ''}
                                                                    name="videoFile"
                                                                    onChange={changeHandler}
                                                                    id="videoFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".videoFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>39</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                पर्यटन संचालनालयाच्या Social Media handles वर सर्व माहिती देण्यात येईल का ? वा collaborate होईल का?/Will all the information be given on the Social Media handles of the Directorate of Tourism? Or will collaborate? (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="socialMediaFile"
                                                                    type="file"
                                                                    onChange={ev => setSocialMediaFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".socialMediaFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.socialMediaFile || ''}
                                                                    name="socialMediaFile"
                                                                    onChange={changeHandler}
                                                                    id="socialMediaFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".socialMediaFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>40</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                कार्यक्रमाचा  पर्यटन वाढीच्या दृष्टीने फलनिष्पती अहवाल सादर करावा/Submit the program`s travel environment performance report (File Size 5 MB)
                                                                <br />
                                                                <TextField
                                                                    id="environmentPerformanceFile"
                                                                    type="file"
                                                                    onChange={ev => setEnvironmentPerformanceFile(ev.target.files[0])}
                                                                    style={{ width: 300 }}
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".environmentPerformanceFile"]}</div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <MDInput label="Enter" fullWidth value={state.environmentPerformanceFile || ''}
                                                                    name="environmentPerformanceFile"
                                                                    onChange={changeHandler}
                                                                    id="environmentPerformanceFile"
                                                                    multiline
                                                                />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".environmentPerformanceFile"]}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default Sponsorship;